@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&display=swap);
*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body{
    background: #0e0e0e;
    color: #dadada;
	margin:0;
	padding:0;
	min-height: 100%;
	font-family: 'Poppins', sans-serif;
	font-size: 10px;
	letter-spacing: 0.5px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}
html{
    min-height: 100%;
    overflow-x: hidden; 
    overflow-y: auto;
    overflow: auto;
    -ms-overflow-style: none; 
    scrollbar-width: none;
}
button,
svg {
  display: inline-block;
  vertical-align: middle;
}
a{
	text-decoration: none;
	transition: .1s ease-out;
	cursor:pointer;
}
h1,h2,h3,h4,h5,h6,p{
	font-size: 10px;
	font-weight: normal;
	margin: 0;
	padding: 0;
	color: #dadada;
}
h2{
	margin: 15px 0 5px 0;
    font-size: 17px;
}
ul{
	list-style: none;
	margin: 0;
	padding: 0;
}
::-webkit-scrollbar{
    width: 0px;
    background: transparent;
}
::-webkit-input-placeholder{
	color: #dadada;
	opacity: 1;
	letter-spacing: 0.5px;
}
:-ms-input-placeholder{
	color: #dadada;
	opacity: 1;
	letter-spacing: 0.5px;
}
::placeholder{
	color: #dadada;
	opacity: 1;
	letter-spacing: 0.5px;
}
:-ms-input-placeholder{
	color: #dadada;
	letter-spacing: 0.5px;
}
::-ms-input-placeholder{
	color: #dadada;
	letter-spacing: 0.5px;
}
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}
#root{
	min-height: 100vh;
}
.App {
    min-height: 100vh;
    padding-top: 90px;
    display: -webkit-flex;
    display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}
.container{
	-webkit-flex: 1 1;
	        flex: 1 1;
}
.text-capitalize{
	text-transform: capitalize;
}

/* MAIN HEADER */

#header{
	height: 90px;
    background-color: #1b1b1b;
    position: fixed;
    top: 0;
    width: 100%;
	z-index: 200;
	box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3);
}
#header a{
	display: -webkit-flex;
	display: flex;
}
#header a .mylogo{
	height: 25px;
}
#header .header-main{
	padding: 5px;
	display: -webkit-flex;
	display: flex;
}
#header .header-main .app-logo{
	-webkit-flex: 1 1;
	        flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
#header .header-main .header-actionbox .action-btn{
	height: 40px;
    width: 45px;
    background-color: #333;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
#header .header-main .header-actionbox .action-btn svg{
	font-size: 18px;
	color: #ef1740;
	display: -webkit-flex;
	display: flex;
}
#header .header-secondary{
	height: 40px;
	background-color: #333;
	display: -webkit-flex;
	display: flex;
}
#header .header-secondary .flex-left{
	width: 50%;
	margin: 5px;
	margin-right: 2.5px;
}
#header .header-secondary .flex-right{
	width: 50%;
	margin: 5px;
	margin-left: 2.5px;
}
.cams-filter{
	width: 100%;
	border: 1px solid #1b1b1b;
    outline: none;
    height: 30px;
	padding: 5px;
    background-color: #1b1b1b;
	color: #dadada;
	font-family: 'Poppins', sans-serif;
	font-size: 10px;
	letter-spacing: 0.5px;
}

/* SEARCH FORM */

#search-form{
	display: -webkit-flex;
	display: flex;
}
.search-input{
	height: 30px;
	width: calc(100% - 40px);
	border: none;
	outline: none;
	padding: 5px 10px;
	background-color: #1b1b1b;
	color: #dadada;
	font-family: 'Poppins', sans-serif;
	font-size: 10px;
	letter-spacing: 0.5px;
	text-transform: uppercase;
}
#search-btn{
	border: none;
	outline: none;
	height: 30px;
	width: 40px;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	background-color: #1b1b1b;
	color: #dadada;
	cursor: pointer;
	transition: .1s ease-in;
}
.fa-search{
	font-size: 14px;
}
#results_suggest{
	position: absolute;
	z-index: 150;
	box-shadow: 0px 3px 5px 0 rgba(0, 0, 0, 0.2);
}
#results_suggest li a{
	height: 30px;
	padding: 5px 10px;
    background-color: #1b1b1b;
	color: #dadada;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	text-transform: uppercase;
}
#results_suggest li a:hover{
	background-color: #1f1f1f;
	color: #fff;
}
#results_suggest li a span{
	margin-right: 5px;
}

/* MOBILE NAV AND OVERLAY */

#main-menu{
	width: 100%;
    color: #dadada;
    position: fixed;
    top: 90px;
    right: 0;
    left: 0;
    bottom: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    z-index: 100;
}
.menu-overlay{
	background-color: rgba(0, 0, 0, 0.9);
	-webkit-flex: 1 1;
	        flex: 1 1;
}
nav{
	background-color: #222;
}
.nav-top-content{
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	background-color: #ef1740;
}
.top-content-text{
	padding: 0 10px;
	font-size: 12px;
	color: #fff;
	width: 100%;
}
.top-content-closebtn{
	height: 35px;
    width: 45px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 5px;
}
.close-icon{
	font-size: 16px !important;
    color: #dadada !important;
}
.nav-main-content{
	display: -webkit-flex;
	display: flex;
	padding: 0 2px;
}
.nav-main-box{
	text-align: center;
	-webkit-flex: 1 1;
	        flex: 1 1;
}
.categ-title{
	padding: 10px;
    margin: 2px;
    margin-top: 4px;
	background-color: #333;
	color: #dadada;
    border: 1px solid #333;
}
.categ-item a{
    padding: 10px;
	background-color: #252525;
    border: 1px solid #333;
    color: #afafaf;
	margin: 4px 2px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	font-weight: 300;
}
.categ-active{
    background-color: #333 !important;
    color: #fff !important;
    border: 1px solid #912539 !important;
}
.nav-bottom-content{
	background-color: #1b1b1b;
	padding: 10px;
	text-align: center;
	color: #dadada;
	font-size: 9px;
	font-weight: 300;
}






/* ROOMS */

.users-topbar{
	padding: 10px;
	padding-bottom: 5px;
	color: #cecece;
	font-size: 12px;
	font-weight: 300;
}
.title_flow{
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	margin-bottom: 5px;
}
.users-title{
	font-weight: 400;
	text-transform: capitalize;
}
.fa-binoculars{
	margin-right: 5px;
	color: #ef1740;
}
.rooms{
	width:calc(100% - 5px);
}
.rooms:after{
	clear:both;
	content:" ";
	display:block;
}
.rooms>div{
	width:calc(50% - 5px);
	margin:5px 0 0 5px;
	float:left;
	position:relative;
}
.rooms>div>div{
	width:100%;
	padding-bottom: 75%;
}
.rooms>div>div>div{
	position:relative;
}
.rooms>div img{
	width:100%;
	display:block;
	background-color: #333;
}
.rooms>div a{
	top:0;
	left:0;
	right:0;
}
.rooms>div .username,.rooms>div a{
	text-decoration:none;
	position:absolute;
	bottom:0;
}
.rooms>div .username{
    color: #fff;
    padding: 0;
	background-color: rgba(0, 0, 0, 0.4);
	display: -webkit-flex;
	display: flex;
	width: 100%;
	padding: 5px;
    font-size: 9px;
    font-weight: 300;
}
.rooms>div .username .nick{
	-webkit-flex-basis: 80%;
	        flex-basis: 80%;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: left;
	white-space: nowrap;
	text-transform: capitalize;
	text-shadow: 1px 1px 1px rgb(0 0 0 / 50%);
}
.rooms>div .username .age{
	-webkit-flex-basis: 20%;
	        flex-basis: 20%;
	text-align: right;
}
.loading_box{
	background-color: rgba(0, 0, 0, 0.4) !important;
}

/* content text */

#content{
	padding: 20px 10px;
	text-align: justify;
	line-height: 2;
}
#content h1{
	font-size: 18px;
	font-weight: 300;
	color: #ef1740;
	margin-bottom: 5px;
	text-transform: capitalize;
}
#content p{
	font-size: 14px;
	line-height: 21px;
	color: #a0a0a0;
	margin-bottom:10px;
}
.text_content{
	background-color: #131313;
    padding: 10px;
}

/* FOOTER */

footer{
	background-color: #222;
	text-align: center;
	font-size: 12px;
}
footer a:hover{
	color: #ef1740;
}
.first-footerbox{
	padding: 10px;
}
.footer-links{
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}
.footer-links a, .footer-links span{
	padding: 5px;
	display: -webkit-flex;
	display: flex;
	color: #dadada;
    font-size: 10px;
}
.second-footerbox{
	padding: 10px;
	background-color: #1b1b1b;
	text-align: center;
	border-top: 1px solid #333;
    border-bottom: 1px solid #333;
}
.second-footerbox h1{
	padding: 10px;
	color: #a0a0a0;
	font-size: 12px;
	font-weight: 300;
}
.second-footerbox p{
	color: #a0a0a0;
	margin: 10px;
	font-weight: 300;
}
.footer-sites{
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}
.footer-sites a{
    padding: 5px 10px;
	display: -webkit-flex;
	display: flex;
	color: #dadada;
    font-size: 10px;
}
.third-footerbox a{
	display: block;
	padding: 10px;
	padding-bottom: 0;
	color: #dadada;
	font-size: 10px;
	font-weight: 300;
}
.third-footerbox p{
    padding: 10px;
    font-weight: 300;
}

/* general pages */

.general{
	padding: 20px;
}
.general h1{
	font-size: 16px;
	color: #dadada;
	margin-bottom: 10px;
}
.general h2{
	font-size: 14px;
	color: #a0a0a0;
	margin-bottom: 5px;
}
.general p{
	font-size: 12px;
	font-weight: 300;
	color: #a0a0a0;
	margin-bottom: 5px;
	text-align: justify;
}

/* LIVEROOM */

#room{
	margin-top: 5px;
}
iframe{
	margin: 0;
    padding: 0;
	border: none;
	display: -webkit-flex;
	display: flex;
}
.room-content{
	background-color: #1b1b1b;
	position: relative;
}
.room-content-top{
    display: -webkit-flex;
    display: flex;
    padding: 10px;
}
.room-info-left{
	height: 30px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
	-webkit-flex: 1 1;
	        flex: 1 1;
	color: #fff;
	font-size: 12px;
	overflow: hidden;
	text-overflow: ellipsis;
	text-transform: capitalize;
}
.room-info-right{
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
}
.live-box{
	padding: 3px 5px;
    color: #dadada;
    background-color: #333;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: 10px;
    font-style: italic;
    font-weight: 300;
}
/* green dot blinker */
.fa-circle{
	height: 6px;
	width: 6px;
	margin-right: 5px;
	color: #03bf00;
	display: -webkit-flex;
	display: flex;
}
.blink{
	-webkit-animation: blinker 1s cubic-bezier(.5, 0, 1, 1) infinite alternate;
	        animation: blinker 1s cubic-bezier(.5, 0, 1, 1) infinite alternate;  
}
  @-webkit-keyframes blinker {  
	from { opacity: 1; }
	to { opacity: 0; }
}
  @keyframes blinker {  
	from { opacity: 1; }
	to { opacity: 0; }
}
.profile-box{
    width: 40px;
    height: 30px;
    margin-left: 10px;
    background-color: #333;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	cursor: pointer;
	transition: .1s ease-out;
}
.profile-box:hover{
	background-color: #2f2f2f;
}
.fa-user-plus{
	display: -webkit-flex;
	display: flex;
	font-size: 12px;
	color: #ef1740;
}
.room-content-middle{
	padding: 10px;
	min-height: 240px;
	background-color: #333;
	display: -webkit-flex;
	display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.welcome-message{
    background-color: #1b1b1b;
    padding: 10px;
    border-radius: 10px;
}
.welcome-message h1{
	font-weight: 300;
}
.room-name{
	font-weight: 400;
	color: #fff;
}
.room-content-bottom{
	display: -webkit-flex;
	display: flex;
	padding: 10px;
}
.room-btn{
    padding: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    background-color: #ef1740;
    color: #fff;
	-webkit-flex: 1 1;
	        flex: 1 1;
	font-size: 12px;
}
.room-btn:hover{
	background-color: #d61439;
}
.private{
	margin-right: 5px;
}
.spyshow{
	margin-left: 5px;
}	
#chat-form{
	display: -webkit-flex;
	display: flex;
}
.chat-input{
    width: 100%;
	padding: 7px 10px 5px 10px;
    border: none;
    outline: none;
    background-color: #1b1b1b;
    color: #dadada;
    font-family: 'Poppins', sans-serif;
	font-size: 10px;
	font-weight: 300;
	letter-spacing: 0.5px;
}
.chat-input:focus{
	background-color: #5f5f5f;
}
.chat-send{
	height: 40px;
	width: 50px;
	color: #dadada;
	background-color: #1b1b1b;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
}
.fa-arrow-right{
	font-size: 16px;
	color: #ef1740;
	display: -webkit-flex;
	display: flex;
}
.browse-more{
	background-color: #0e0e0e;
    padding: 30px 10px 10px 10px;
	font-size: 14px;
	font-weight: 300;
}
.chatroom-bottom-space{
	height: 5px;
}

/* MODEL INFO POPUP */

#info-popup{
	background-color: #333;
    position: absolute;
    top: 50px;
    left: 0;
    bottom: 116px;
	right: 0;
	padding: 10px;
	overflow-y: scroll;
	cursor: auto;
}
.popup{
	position: relative;
}
.fa-times{
	display: -webkit-flex;
	display: flex;
    font-size: 14px;
    color: #ef1740;
}
.info-popup-top{
	display: -webkit-flex;
	display: flex;
	margin-bottom: 10px;
}
.info-popup-top-left{
	-webkit-flex: 1 1;
	        flex: 1 1;
}
.info-popup-top-left div{
	margin-bottom: 2px;
}
.info-popup-top-left div label{
	margin-right: 10px;
	font-weight: 300;
	color: #fff;
}
.info-popup-top-left div span{
	text-transform: capitalize;
	font-weight: 300;
	font-style: italic;
}
.model-thumb-link{
	display: -webkit-flex;
	display: flex;
    position: absolute;
    top: 0;
    right: 0;
}
.model-thumb{
	height: 90px;
}
.info-popup-bottom{
	font-weight: 300;
	font-style: italic;
	text-transform: capitalize;
}
.info-popup-bottom label{
	margin-right: 10px;
	color: #fff;
}

/* HEADER STARTING AT 769 PX*/

.header-xl{
	height: 121px;
	background-color: #222;
}
.header-xl-top{
	padding: 10px;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	height: 60px;
}
.header-xl-part-left{
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}
.header-xl-part-left-logo a{
	display: -webkit-flex;
	display: flex;
}
.mylogo-xl{
	height: 40px;
}
.header-xl-part-left-modelslive{
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	margin-left: 10px;
	font-weight: 300;
}
.header-xl-part-left-modelslive span{
	margin-right: 2px;
	font-size: 12px;
}
/* navbar desktop*/
.header-xl-bottom{
	background-color: #333;
	border-top: 1px solid #ef1740;
}
.nav-list{
	display: -webkit-flex;
	display: flex;
}
.nav-link{
	display: -webkit-flex;
	display: flex;
	padding: 6px 14px;
	color: #dadada;
}
.nav-link:hover{
	color: #fff;
	background-color: #3a3a3a;
}
.link-active{
	background-color: #ef1740 !important;
    color: #fff;
}
.nav-btn-login{
	margin-left: auto;
}
.nav-btn-login div{
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	padding: 5px 14px;
    color: #dadada;
	font-size: 12px;
	cursor:pointer;
}
.nav-btn-login div:hover{
	color: #fff;
	background-color: #3a3a3a;
}
.circle-icon-desktop{
	margin-left: 5px;
}
.nav-btn-account div{
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	padding: 5px 14px;
	background-color: #ef1740;
    color: #fff;
	font-size: 12px;
	cursor:pointer;
}
.nav-btn-account div:hover{
	background-color: #d61439;
}
.header-tags{
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	background-color: #131313;
	padding: 5px;
	padding-bottom: 0;
	border-bottom: 1px solid #222;
}
.tag-link{
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	padding: 5px;
	font-weight: 300;
	font-style: italic;
	color: #cecece;
}
.tag-link:hover{
	color: #fff;
}
.fa-tag{
	margin-right: 3px;
	font-size: 8px;
	color: #ef1740;
}
.tag-active{
	color: #fff;
	background-color: transparent !important;
	text-decoration: underline;
	-webkit-text-decoration-color: #ef1740;
	        text-decoration-color: #ef1740;
}
.user-balance-info{
	margin-left: auto;
	margin-right: 5px;
}
.your-balance-info span{
	font-weight: 300;
}

/* Registration */

.modal {
	position: fixed;
	z-index: 1;
	background-color: rgba(0, 0, 0, 0.90);
	top:0;
	bottom:0;
	left:0;
	right:0;
}
.model_register {
	width: 90%;
	max-height: 202px;
	max-width: 400px;
	padding: 20px;
	background-color: #222;
	border: 1px solid #131313;
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
	z-index:1000;
	overflow: hidden;
    text-overflow: ellipsis;
}
.registerForm{
    display: -webkit-flex;
    display: flex;
}
.model_register input{
	width: 100%;
    font-size: 14px;
	padding: 10px;
	border: 0;
	outline: 0;
	color: #8c8c8c;
	letter-spacing: 0.5px;
    background-color: #131313;
}
.model_register input::-webkit-input-placeholder{
	color: #8c8c8c;
}
.model_register input:-ms-input-placeholder{
	color: #8c8c8c;
}
.model_register input::placeholder{
	color: #8c8c8c;
}
.model_register button{
	width: 70px;
    text-transform: uppercase;
    background-color: #333;
    cursor: pointer;
    border: 0;
    border-radius: 0;
	outline: none;
}
.model_register button:hover{
    background-color: #2f2f2f;
}
.model_register button svg{
	display: inline-block;
	vertical-align: middle;
    margin: 0;
	font-size: 16px;
	color: #ef1740;
}
.flexdiv{
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
	margin-bottom: 20px;
}
.model_register .close{
    width: 30px;
    height: 30px;
    color: #fff;
    font-size: 30px;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
.model_register .close:hover{
	color: #ef1740;
}
.model_register h1{
    font-size: 16px;
    color: #fff;
}
.model_register h2{
    margin-top: 20px;
	font-size: 12px;
	font-weight: 300;
    color: #dedede;
	text-align: justify;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;  
	overflow: hidden;
}

/* MEDIA QUERIES */

@media only screen and (min-width: 320px) {
	#header a .mylogo {
		height: 30px;
	}
}

@media only screen and (min-width: 360px) {
	#header a .mylogo {
		height: 35px;
	}
}

@media only screen and (min-width: 568px) {
	.rooms>div {
		width: calc(33.3333% - 5px);
	}
	.footer-links a{
		padding: 5px 10px;
	}
}

@media only screen and (min-width: 768px) {
	.rooms>div {
		width: calc(25% - 5px);
	}
	.footer-links a {
		padding: 5px 20px;
	}
}




/* DESKTOP VERSION */

@media only screen and (min-width: 769px) {
	.rooms>div {
		width: calc(25% - 5px);
	}
	.App{
		padding-top: 0;
	}
	.users-topbar{
		padding: 5px;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: space-between;
		        justify-content: space-between;
		-webkit-align-items: center;
		        align-items: center;
	}
	.cams-filter{
		width: 150px;
		height: unset;
		background-color: #222;
	}
	.container{
		padding: 5px;
	}
	.search-input{
		width: 200px;
		padding: 6px 10px 4px 10px;
	}
	#search-btn{
		background-color: #333;
	}
	.users-heading{
		font-size: 14px;
	}
	.users-title{
		font-size: 14px;
	}
	.fa-binoculars {
		font-size: 15px;
	}
	#content {
		padding: 50px 10px;
	}
	#content h1{
		font-size: 19px;
	}
	h2{
		font-size: 19px;
	}
	#content p {
		font-size: 15px;
		line-height: 22px;
	}
	#search-btn:hover {
		color: #ef1740;
	}
	.info-popup-top-left div{
		margin-bottom: 5px;
	}
	.model-thumb {
		height: 100px;
	}
	.rooms>div .username{
		font-size: 10px;
	}
	.text_content {
		padding: 20px;
	}
	.container {
		margin-bottom: 0;
	}
	.modal {
		background-color: rgba(0, 0, 0, 0.85);	
	}
}

@media only screen and (min-width: 1024px) {
	.rooms>div {
		width: calc(20% - 5px);
	}
}

@media only screen and (min-width: 1280px) {
	.rooms>div {
		width: calc(16.6666666667% - 5px);
	}
	.info-popup-top-left div label, .info-popup-bottom label{
		font-size: 12px;
	}
	.info-popup-top-left div span{
		font-size: 12px;
	}
	.info-popup-bottom{
		font-size: 12px;
	}
	.room-info-left{
		font-size: 15px;
	}
}

@media only screen and (min-width: 1520px) {
	.rooms>div {
		width: calc(14.2857142857% - 5px);
	}
	.room-centered{
		width: 1500px;
		margin: 0 auto;
	}
}

@media only screen and (min-width: 1920px) {
	.rooms>div {
		width: calc(12.5% - 5px);
	}
}

@media only screen and (min-width: 2200px) {
	.rooms>div {
		width: calc(11.1111111111% - 5px);
	}
}

@media only screen and (min-width: 2560px) {
	.rooms>div {
		width: calc(10% - 5px);
	}
}

@media only screen and (min-width: 3000px) {
	.rooms>div {
		width: calc(9.09090909091% - 5px);
	}
}

@media only screen and (min-width: 3300px) {
	.rooms>div {
		width: calc(8.33333333333% - 5px);
	}
}

@media only screen and (min-width: 3600px) {
	.rooms>div {
		width: calc(7.69230769231% - 5px);
	}
}
